import React from "react";

const InCart = ({ inCart }) => {
  return (
    <svg
      className={inCart ? "cart in_cart" : "cart"}
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="34.9494" height="34.9494" rx="17.4747" fill="#93A27C" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.30593 9.08824C9.0766 9.20949 8.98406 9.38535 9.00222 9.6652C9.0191 9.92508 9.10675 10.0613 9.32753 10.1707C9.46831 10.2405 9.56359 10.2494 10.1674 10.2496C10.89 10.2498 11.0635 10.2774 11.3061 10.4305C11.5355 10.5753 11.6627 10.8166 11.9188 11.5926L12.1523 12.3002L12.4872 15.3275C12.6714 16.9925 12.8459 18.4427 12.8748 18.5502C12.956 18.8513 13.0399 19.0263 13.2329 19.2968C13.5708 19.7703 14.1145 20.1093 14.6964 20.2093C14.8743 20.2398 16.0866 20.2494 18.7585 20.2413L22.5671 20.2298L22.8305 20.1274C23.5599 19.8436 24.04 19.3499 24.2819 18.635C24.3198 18.5227 24.4651 17.3832 24.6405 15.8225C24.9705 12.8859 24.9798 12.6799 24.7993 12.328C24.6121 11.9634 24.3163 11.6988 23.9474 11.5662C23.8496 11.5311 22.4924 11.5144 18.5081 11.4996L13.199 11.4798L13.013 10.9234C12.9107 10.6174 12.7698 10.2659 12.6999 10.1422C12.5114 9.80906 12.1062 9.42699 11.7615 9.25731C11.3379 9.04887 11.0464 9.00086 10.2002 9.00004C9.51929 8.99942 9.46347 9.00492 9.30593 9.08824ZM14.3916 21.5602C13.5353 21.7907 12.9773 22.5065 12.9773 23.3744C12.9773 24.2507 13.5395 24.9642 14.4082 25.1905C15.4424 25.4598 16.5173 24.7278 16.6876 23.638C16.798 22.9319 16.427 22.1466 15.807 21.7738C15.3933 21.525 14.8351 21.4408 14.3916 21.5602ZM21.8916 21.5602C21.0353 21.7907 20.4773 22.5065 20.4773 23.3744C20.4773 24.2507 21.0395 24.9642 21.9082 25.1905C22.9424 25.4598 24.0173 24.7278 24.1876 23.638C24.298 22.9319 23.927 22.1466 23.307 21.7738C22.8933 21.525 22.3351 21.4408 21.8916 21.5602Z"
        fill="white"
      />
    </svg>
  );
};

export default InCart;
