import React from "react";

const ArrowBackIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.43431 20.5657C7.12189 20.2533 7.12189 19.7467 7.43431 19.4343L12.5255 14.3431C12.8379 14.0307 13.3444 14.0307 13.6569 14.3431C13.9693 14.6556 13.9693 15.1621 13.6569 15.4745L9.13137 20L13.6569 24.5255C13.9693 24.8379 13.9693 25.3444 13.6569 25.6569C13.3444 25.9693 12.8379 25.9693 12.5255 25.6569L7.43431 20.5657ZM32 20.8H8V19.2H32V20.8Z"
        fill="#8B8B8B"
      />
    </svg>
  );
};

export default ArrowBackIcon;
