import React from "react";

const Burger = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="24" fill="#EDEDED" />
      <path d="M14 18.2861H34" stroke="#373737" stroke-linecap="round" />
      <path d="M14 24H34" stroke="#373737" stroke-linecap="round" />
      <path d="M14 29.7148H34" stroke="#373737" stroke-linecap="round" />
    </svg>
  );
};

export default Burger;
