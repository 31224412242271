import React from "react";

const InProcess = () => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.50008 1.16675C6.34636 1.16675 5.21854 1.50887 4.25926 2.14984C3.29997 2.79082 2.5523 3.70186 2.11079 4.76776C1.66928 5.83366 1.55376 7.00655 1.77884 8.13811C2.00392 9.26966 2.55949 10.3091 3.37529 11.1249C4.1911 11.9407 5.2305 12.4962 6.36206 12.7213C7.49361 12.9464 8.6665 12.8309 9.7324 12.3894C10.7983 11.9479 11.7093 11.2002 12.3503 10.2409C12.9913 9.28162 13.3334 8.15381 13.3334 7.00008C13.3317 5.45351 12.7166 3.97076 11.623 2.87717C10.5294 1.78357 9.04666 1.16845 7.50008 1.16675ZM9.82292 9.32292C9.71353 9.43227 9.56518 9.49371 9.4105 9.49371C9.25582 9.49371 9.10747 9.43227 8.99808 9.32292L7.08825 7.41308C6.97914 7.30323 6.91754 7.15491 6.91675 7.00008V4.66675C6.91675 4.51204 6.97821 4.36367 7.0876 4.25427C7.197 4.14487 7.34537 4.08341 7.50008 4.08341C7.65479 4.08341 7.80317 4.14487 7.91256 4.25427C8.02196 4.36367 8.08342 4.51204 8.08342 4.66675V6.75858L9.82292 8.49808C9.93228 8.60747 9.99371 8.75582 9.99371 8.9105C9.99371 9.06518 9.93228 9.21352 9.82292 9.32292Z"
        fill="#111928"
      />
    </svg>
  );
};

export default InProcess;
