import React from "react";

const Cart = () => {
  return (
    <svg
      style={{ marginRight: 8 }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.38332 1.96385C2.15399 2.0851 2.06145 2.26096 2.07961 2.54081C2.09649 2.80069 2.18415 2.9369 2.40493 3.04628C2.54571 3.11608 2.64098 3.12503 3.24477 3.12522C3.96739 3.12542 4.14094 3.153 4.38352 3.30612C4.6129 3.45092 4.74012 3.69225 4.99618 4.46819L5.22969 5.17577L5.56461 8.20311C5.74883 9.86815 5.92325 11.3183 5.95219 11.4258C6.03336 11.7269 6.11731 11.9019 6.31032 12.1724C6.64821 12.6459 7.19192 12.9849 7.77383 13.0849C7.95168 13.1154 9.16399 13.125 11.8359 13.1169L15.6445 13.1055L15.9079 13.003C16.6373 12.7192 17.1174 12.2255 17.3593 11.5106C17.3972 11.3983 17.5425 10.2588 17.7179 8.69807C18.0479 5.76151 18.0572 5.55553 17.8766 5.20366C17.6895 4.83897 17.3937 4.5744 17.0248 4.44178C16.927 4.40667 15.5698 4.39003 11.5855 4.37522L6.27637 4.35546L6.09039 3.79905C5.98813 3.49303 5.84719 3.14147 5.77727 3.0178C5.58883 2.68467 5.18364 2.3026 4.83887 2.13292C4.41532 1.92448 4.12379 1.87647 3.27762 1.87565C2.59668 1.87503 2.54086 1.88053 2.38332 1.96385ZM7.46899 14.4358C6.61274 14.6663 6.05469 15.3821 6.05469 16.25C6.05469 17.1263 6.61692 17.8398 7.48559 18.0661C8.51977 18.3355 9.59469 17.6034 9.76497 16.5137C9.87536 15.8075 9.50438 15.0222 8.88442 14.6494C8.47067 14.4007 7.9125 14.3164 7.46899 14.4358ZM14.969 14.4358C14.1127 14.6663 13.5547 15.3821 13.5547 16.25C13.5547 17.1263 14.1169 17.8398 14.9856 18.0661C16.0198 18.3355 17.0947 17.6034 17.265 16.5137C17.3754 15.8075 17.0044 15.0222 16.3844 14.6494C15.9707 14.4007 15.4125 14.3164 14.969 14.4358Z"
        fill={"white"}
      />
    </svg>
  );
};

export default Cart;
